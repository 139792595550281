import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import LeftFeatureVideo from '../components/left-feature-video';
import FeaturePararaph from '../components/feature-paragraph'
import {KGlackinTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import FreeTrialButton from '../components/free-trial-button';

import visitorSrc from '../images/visitor.svg';
import notificationSrc from '../images/notification.svg';
import visitorBookSrc from '../images/visitor-book.png';
import ipadVisitorEntrySrc from '../images/ipad-visitor-entry.png';

const VisitorBook = () => (
  <Layout>
    <SEO 
    	title="Digital Visitor Management System"
    	description="Our visitor management system can keep track of the visitior coming and going in your business."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Keep track of 
			            <br />
			            <span className="text-highlight-600">visitors coming and going </span> in your business
			          </h2>

			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Impress your guests with a sleek modern visitor sign in via a tablet. TimeKeeper will keep an up to date 
			            digital visitor book so your guests are accounted for.
			          </p>
			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            <i>Paid add on at £29/month.</i>
			          </p>
			          <FreeTrialButton />
			          <AppStoreIcons />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={visitorSrc} alt="Visitor Sign In" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				     <RightFeature 
				    	sectionTitle="Sleek, modern  visitor experience" 
				    	title="Impress your visitors on entry" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          Let's face it, a paper sign in books isn't a good first impression.
						        </FeaturePararaph>
						        <FeaturePararaph>
						          Instead present a sleek iPad or Android tablet at reception, that gives your business a modern, professional look.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={ipadVisitorEntrySrc}
				    	imgAlt="Modern sleek visitor entry"
				    />
				    <LeftFeature 
				    	sectionTitle="Digital Visitor Book" 
				    	title="Paperless, digital visitor book" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          Need to check who's visited last week? Maybe you are tired of flicking through paper visitor books.
						        </FeaturePararaph>
						        <FeaturePararaph>
						          TimeKeeper allows you to maintain a digital visitor book that allows you to see who's currently visiting in each location.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={visitorBookSrc}
				    	includeShadow={true}
				    	imgAlt="Paperless digital visitor book"
				    />
				    <RightFeature 
				    	sectionTitle="Instant Employee Visitor Notifications" 
				    	title="Notify your employees when guests arrive" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          When a visitor select the employee they are there to visit, TimeKeeper will notify the employee in question with an email and push notification.
						        </FeaturePararaph>
						        <FeaturePararaph>
						          This means your employee will be aware instantly when their visitor is waiting at Reception.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={notificationSrc}
				    	imgAlt="Notifying employees via email/notification"
				    />
				    <LeftFeatureVideo 
				    	sectionTitle="Simple visitor sign in and out" 
				    	title="Let's see it in action" 
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Once the visitor add on has been enabled on your account, visitors can sign in and out via the TimeKeeper kiosk.
						        </FeaturePararaph>
						        <FeaturePararaph>
						         TimeKeeper will keep track of all the visitiors signed in and out across your premises. This means your "Who's In" report is kept up to date with your employees and visitors. 
						         As well that, TimeKeeper will keep a digitial visitor book allowing you to keep an audit trail of visitors in your business.
						        </FeaturePararaph>
						    </div>
				    	}
				    	rightChild={
				    		<div className="flex w-full justify-center mt-4">
				              <iframe
				                width="560" 
				                height="315"
				                src="https://www.youtube.com/embed/BUQtM7OhwfA"
				                title="Visitor Management System"
				                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				                frameBorder="0"
				                webkitallowfullscreen="true"
				                mozallowfullscreen="true"
				                allowFullScreen
				              />
				            </div>
				    	} />
				</div>
				<KGlackinTestimonial />
				<FreeTrial part1={
					<span>Sign up to your new <br /> visitor experience today!</span>
				} />
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default VisitorBook
